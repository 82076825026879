const container = document.querySelector('.technologies ');
const circles = document.querySelectorAll('.technologies .item');
const rotation = 360/circles.length;

function circular() {
  let radius = window.innerWidth > 768 ? 250 : 150;
  circles.forEach((circle,i) => {
    const value = `rotate(${i*rotation}deg) translate(${radius}px) rotate(-${i*rotation}deg)`;
    circle.style.transform = value;
  });
}
circular();

window.addEventListener('resize', circular);