import axios from 'axios';

document
  .querySelector('#contact-form')
  .addEventListener('input', async function (e) {
    const spanError = document.querySelector('.error');
    if (e.target.value.length > 2 && !e.target.validity.valid) {
      if (e.target.name === 'email') {
        spanError.textContent = `Input the valid email. Example: test@gmail.com`;
      } else if (e.target.name === 'phone') {
        spanError.textContent = `Input the valid phone. Example: +XX-XXX-XXX-XX-XX`;
      } else {
        spanError.textContent = `The field ${e.target.name} includes invalid value`;
      }
    } else {
      spanError.textContent = '';
    }
  });
  
document
  .querySelector('#contact-form')
  .addEventListener('submit', async function (e) {
        e.preventDefault();

    const formData = new FormData(document.querySelector('#contact-form'));
    const contactFormData = {};
    formData.forEach(function (value, key) {
      contactFormData[key] = value;
    });

    const token = import.meta.env.VITE_TELEGRAM_TOKEN;
    const chat_id = import.meta.env.VITE_CHAT_ID;

    let message = `<b>Message from the Portfolio website</b>\n`;
    message += `<b>Sender: ${contactFormData.name}</b>\n`;
    message += `<b>Email: ${contactFormData.email}</b>\n`;
    message += `<b>Phone: ${contactFormData.phone}</b>\n`;
    message += `<b>Comments: ${contactFormData.comments}</b>`;

    try {
      const response = await axios.post(
        `https://api.telegram.org/bot${token}/sendMessage`,
        {
          chat_id,
          parse_mode: 'html',
          text: message,
        }
      );
      document.querySelector('#contact-form').reset();
      return response;
    } catch (error) {
      console.log(error);
      return [];
    }
  });
